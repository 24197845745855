import React, { Component } from 'react';
import Header from '../Components/Header.jsx';
import Newsletter from '../Components/Newsletter.jsx';
import Footer from '../Components/Footer.jsx';
import { Trans } from "react-i18next";
import { Helmet } from "react-helmet";

class Comments extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="keywords" content="Hotel, Ensenada, hotel en ensenada, ensenada hoteles, ensenada hotel, ensenada hoteles, stay in ensenada, comments" />
                    <link rel="canonical" href="http://hotelvillafontanainn.com" />
                    <title>Hotel Villa Fontana Inn</title>
                </Helmet>
                <Header />
                <div className="comments">
                    <h1><Trans>comments section</Trans></h1>
                </div>
                <Newsletter />
                <Footer />
            </div>
        );
    }
}
export default Comments;