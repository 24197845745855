import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
        en: {
            translation: {
                "home" : "Home",
                "rooms" : "Rooms",
                "gallery" : "Gallery",
                "comments section" : "Comments section",
                "whereto" : "Where to go",
                "contact" : "Reservations",
                "caption1": "Great Location",
                "caption2": "Close to best restaurants",
                "caption3": "Friendly Staff",
                "description1": "At 5 minutes from the beach and 2 blocks from the promenade by the sea esplanade",
                "brochure": "To enjoy your visit we recommend bring your reservation with you, and to protect all we kindly as you that: <br> - Wear protective mask or face covering <br> - Use hand sanitizer and/or wash hands frequently <br> - Mantain safe distance from others",
                "brochure1" : "EnsenadaKnown as the 'Pacific's Cinderela', Ensenada includes several interesting sites, such as the State Arts Center, the City Theater, the Regional History Museum, the Rivera Social, Civic and Cultural Center, the Former Rivera Pacific Casino; the traditional Husong's Cantina.<br/><br/>      In 1888, the first mexican winery, Bodegas de Santo Tomas started operating in the city.<br/><br/>      There are also beatiful natural areas, such as Isla de Guadalupe Biosphere Reserve; La Bufadora, a sea-geyser reaching up to 18 meters high; the Constitucion de 1857 National Park, Hanson Lake; the Island and Bay Todos Santos, an excellent location gray whales sighting, and San Miguel, El Faro, Baja and Estero Beaches. ",
                "brochure2" : "Sierra de San Pedro Mártir National ParkWith its 63,000 hectareas, it possesses almost vertical walls and cliffs descending to the San Felipe Desert. It is the highest mountain sierra in the peninsula and its highest summit is the Picacho del Diablo, reaching 3,100 meters MSL. It is a place with ideal conditions for astronomical observation, it hosts the National Astronomical Observatory one of the best observatories worldwide. ",
                "brochure3" : "Constitucion de 1857 National ParkIt covers a surface of 5,009 hectares and is one of the few green areas in Baja California. It possesses pines and oak forests growing.It is located at the heart of Sierra Juarez.<br/><br/>Valle de GuadalupeA region famous for its ample vineyards, the basis for the production of world-quality wines, which accounts for 90% of the national production of table wine. The valley is also rich in olive and orange orchards.<br/>",
                "connect with us" : "Connect With Us",
                "social media" : "Social Media Channels",
                "featured" : "Featured Rooms",
                "ft room text" : "We have sixty six nicely appointed rooms ans suites with cathedral ceilings, balconies, two queen or king size bedrooms that will give you a restful atmosphere to enjoy your stay.",
                "SingleQs title" : "Single Queen Size",
                "SingleKs title" : "Single King Size",
                "DoubleQs title" : "Double Queen Size",
                "TwoDs title" : "Two Double Size",
                "JrSuite title" : "Jr. Suite",
                "FamSuite title" : "Family Suite",
                "SingleQs text" : "Room with 1 Queen Size Bed, for 1 - 2 persons.",
                "SingleKs text" : "Room with 1 King Size Bed, for 1 - 2 persons.",
                "DoubleQs text" : "Room with 2 Queen Size Beds, for 1 - 4 persons.",
                "TwoDs text" : "Room with 2 Double Size Beds, for 1 - 4 persons.",
                "JrSuite text" : "Jr. Suite",
                "FamSuite text" : "Family Suite",
                "priceSingleQs" : "$65.00 USD",
                "priceSingleKs" : "$73.00 USD",
                "priceDoubleQs" : "$92.00 USD",
                "priceTwoDs" : "$80.00 USD",
                "priceJrSuite" : "$122.50 USD",
                "priceFamSuite" : "$145.00 USD",
                "book now" : "Book Now",
                "places near" : "Places Near Us",
                "restaurants" : "Restaurants",
                "museums" : "Museums",
                "barsnclubs" : "Bars & Clubs",
                "bars" : "Bars",
                "festivals" : "Festivals and Tourism",
                "restaurants text" : "Ensenada has several choice restaurants located in the main part of town as well as in the outskirts, for the adventurous. Ensenada restaurants serve everything from the morning caught seafood which includes succulent lobster to the international cuisine for all delicacies.",
                "museums text" : "While visiting the cultural attractions in Ensenada would prove to be informative, attending its artsy events will leave you entertained. From cultural performances to historical monuments that give you a glimpse of the past, take a look at this list to know Ensenada better. Include these in attractions in your itinerary for Ensenada and have a great vacation.",
                "bars text" : "There are several all night party venues that get packed with patrons, especially on the weekends when the cruise ships check into port. This place gives you the chance to unwind from your every day worries.",
                "festivals text" : "In Ensenada you will find Guadalupe Island, the best place in the world to see white sharks. It also has a calm sea, hills, the gigantic part-desert, part- tropical valley, and its mountains that frame the national parks; you will find cave paintings and a spectacular marine geyser called La Bufadora, a deep underwater canyon that ends in a cave at the risk. This marine geyser rises more than 30 meters above sea level, producing a sound that gave it its name.",
                "otherRestaurants" : "Italian cuisine<br>Chinese cuisine<br>Japanese cuisine<br>Mexican cuisine<br>Seafood<br>",
                "contactus" : "Reservations",
                "payments" : "We accept all major credit cards",
                "contact address" : "1050 Lopez Mateos Av, Ensenada, BC 22800",
                "connect" : "Connect with us",
                "socialmedia" : "Social Media Channel",
                "gallery t":"Photo Gallery",
                "footer text": "You have questions regarding our services? Contact us, we will be happy to help you out!",
                "amenities title" : "Our facilities include:",
                "amenity1" : " Air Conditioning",
                "amenity2" : " Heating",
                "amenity3" : " Color cable remote control (ESPN, HBO, all USA  Major Channels)",
                "amenity4" : " Room Service",
                "amenity5" : " Side walk cafe",
                "amenity6" : " Telephone in Rooms",
                "amenity7" : " Swimming Pool",
                "amenity8" : " Jacuzzi",
                "amenity9" : " Convenience Store",
                "amenity10" : " Boutiques",
                "amenity11" : " Panoramic Balconies",
                "amenity12" : " Jr.  Suites with large roman tubs",
                "amenity13" : " Private Parkings",
                "amenity14" : " Security 24 hours",
                "amenity15" : " Taxi lane",
                "amenity16" : " Coffee maker",
                "amenity17" : " Close to all Major Ensenada Attractions",
                "amenity18" : " Turist Information and tours",
                "amenity19" : " Free WIFI",
                "about" : "About Ensenada",
                "aboutText" : "It is a city in the mexican state of Baja California. It is head of the municipality of the same name, and is located at 31 ° 52 'north latitude and 116 ° 37' west longitude. The city is located approximately 110 km (75 miles) from the US-Mexico border, facing the Pacific Ocean and was founded by Juan Rodríguez Cabrillo. Ensenada was the capital of Baja California from 1882 to 1915, and the capital became Mexicali during the revolution. To date, the city is a tourist center and a weekend retreat for millions of visitors per year.<br><br>The municipality of Ensenada has two national parks in the southeast of the municipality, the Constitución National Park of 1857 (Sierra de Juárez) and San Pedro Mártir, where the National Astronomical Observatory is located. Ensenada is scientific research, both basic and applied, and declared by the local municipality as the City of Knowledge.<br><br>In 2018 the Metropolitan Area of Ensenada was declared, which includes the center of Ensenada, El Sauzal, Ejido Chapultepec, Maneadero, El Zorrillo and Esteban Cantú, Estero de Punta Banda and La Joya. It is known as the Capital of Mexican Wine and Capital of the Off Road. Ensenada is part of the UNESCO Creative Cities Network since 2015. The city is part of the organization Surfing World Reserves since 2014.",
                "placestovisit" : "Places to visit",
                "placestovisit_description": "There are a lot of places nearby you can visit while you enjoy your stay with us",
                "contactus_description": "Keep in touch with us or find us in the map",
                "santo_tomas_description": "The oldest winery in Ensenada, Baja California with more than 125 years making wine and innovating olive oils extra virgin. Located 6 blocks away from us.",
                "muelle3_description": "Beer – Traditional Cantina – Gourmet Burgers.",
                "guerrerense_description": "Mariscos La Guerrerense was founded in 1960. From Ensenada to the world, its dishes fuse innovation, flavor and tradition. <br> Recommended by Anthony Bourdain.",
                "valle_description": "This town is about 30 minutes from the center of Ensenada. The wine area extends from the north shore of the city of Ensenada to the south of Tecate, in Baja California.",
                "paipai_description":"We are an organization that offers eco-sustainable tourist services, we emphasize on promoting the care of ecosystems and the conservation of biodiversity.",
                "malecon":"Promenede by the sea",
                "malecon_description": "The short walking tour of less than a kilometer by this tourist place, offers great panoramic views of the Port.<br> Located one block away from us.",
                "la_bufadora_description": "It is a Geiser located on the South of Ensenada. Visited by many people per year.",
                "hussongs_description": "Hussong's is the name of the oldest cantina and the best known around Ensenada and also California.",
                "breakfast_info": "Book 2 nights and get 1 free breakfast for 2 people.",
                "breakfast_info_disclaimer": "Does not apply for holidays or special events",
                "open_turism": "We are sanitizing and following safety guidelines to protect our customers and our team",
                "clean_virus_certified": "\"Constantly virus clean certified\"",
                "pool_open": "Pool Open",
                "new_year_promo_title": "Winter's Promo $1,200.00 mexican pesos for 1 Night",
                "new_year_promo_description": "Fees are for 1 or 2 persons no taxes included <br> Stay from January 1st to February 11th, 2021 with previous reservation",
                "reservation_banner_message": "Want to book with us?",
                "click_here_message": "Click Here!"
        }
      },
        es: {
            translation: {
                "home" : "Inicio",
                "rooms" : "Habitaciones",
                "gallery" : "Galería",
                "comments section" : "Comentarios",
                "whereto" : "Lugares que visitar",
                "contact" : "Reservaciones",
                "caption1": "En el corazón de la Zona Turística",
                "caption2": "Cerca de los mejores Restaurantes",
                "caption3": "Ambiente Familiar",
                "description1": "Paseo por la calle turística, a 5 minutos de la playa y a 2 cuadras del malécon",
                "brochure": "Recomendaciones para su visita: <br> - Traer su reservación confirmada <br> - Use cubrebocas y/o careta en áreas públicas <br> - Use gel antibacterial y/o lave constantemente sus manos <br> - Mantenga la sana distancia",
                "brochure1" : "EnsenadaConocida como la 'Cenicienta del Pacífico', Ensenada incluye varios sitios interesantes, como el Centro de Artes del Estado, el Teatro de la Ciudad, el Museo de Historia Regional, el Centro Social, Cívico y Cultural de Rivera, el Antiguo Casino Rivera Pacific; La tradicional cantina de Husong.<br/><br/>      En 1888, comenzó a operar en la ciudad la primera bodega mexicana, Bodegas de Santo Tomás.<br/><br/>      También hay hermosas áreas naturales, como la Reserva de la Biosfera Isla de Guadalupe; La Bufadora, un géiser de mar que alcanza hasta 18 metros de altura; el Parque Nacional Constitución de 1857, lago Hanson; la isla y la bahía Todos Santos, una excelente ubicación para avistar ballenas grises, y las playas de San Miguel, El Faro, Baja y Estero. ",
                "brochure2" : "Sierra de San Pedro MártirCon sus 63,000 hectáreas, posee paredes casi verticales y acantilados que descienden hacia el desierto de San Felipe. Es la sierra montañosa más alta de la península y su cumbre más alta es el Picacho del Diablo, alcanzando 3.100 metros MSL. Es un lugar con condiciones ideales para la observación astronómica, alberga el Observatorio Astronómico Nacional, uno de los mejores observatorios del mundo.",
                "brochure3" : "Parque nacional Constitucion de 1857Cubre una superficie de 5,009 hectáreas y es una de las pocas áreas verdes en Baja California. Posee pinos y bosques de robles en crecimiento. Está ubicado en el corazón de Sierra Juárez.<br/><br/>Valle de GuadalupeUna región famosa por sus amplios viñedos, la base para la producción de vinos de calidad mundial, que representa el 90% de la producción nacional de vino de mesa. El valle también es rico en olivares y naranjos.<br/>",
                "connect with us" : "Conéctate Con Nosotros",
                "social media" : "Nuestras Redes Sociales",
                "featured" : "Habitaciones Recomendadas",
                "ft room text" : "Tenemos sesenta y seis habitaciones y suites  bien equipadas, recamaras que le daran una atmosfera relajante para disfrutar su estancia.",
                "SingleQs title" : "Single Queen Size",
                "SingleKs title" : "Single King Size",
                "DoubleQs title" : "Double Queen Size",
                "TwoDs title" : "Two Double Size",
                "JrSuite title" : "Jr. Suite",
                "FamSuite title" : "Family Suite",
                "SingleQs text" : "Habitación con 1 cama Queen Size, para 1 - 2 personas.",
                "SingleKs text" : "Habitación con 1 cama King Size, para 1 - 2 personas.",
                "DoubleQs text" : "Habitación con 2 camas Queen Size, para 1 - 4 personas.",
                "TwoDs text" : "Habitacion con 2 camas Double Size, para 1 - 4 personas.",
                "JrSuite text" : "Habitacion con 2 camas Matrimoniales, para 1 - 4 personas.",
                "FamSuite text" : "Habitacion con 2 camas Matrimoniales, para 1 - 4 personas.",
                "priceSingleQs" : "$1,186.25 MXN",
                "priceSingleKs" : "$1,341.50 MXN",
                "priceDoubleQs" : "$1,679.00 MXN",
                "priceTwoDs" : "$1,460.00 MXN",
                "priceJrSuite" : "$2,236.00 MXN",
                "priceFamSuite" : "$2,646.25 MXN",
                "book now" : "Reservar",
                "places near" : "Lugares Cercanos",
                "restaurants" : "Restaurantes",
                "museums" : "Museos",
                "bars" : "Bares",
                "barsnclubs" : "Bares & Clubs",
                "festivals" : "Festivales y Turismo",
                "restaurants text" : "Ensenada tiene varios restaurantes de elección ubicados en la parte principal de la ciudad, así como en las afueras, para los aventureros. Los restaurantes de Ensenada sirven todo, desde los mariscos capturados por la mañana, que incluyen la suculenta langosta hasta la cocina internacional para todas las delicias.",
                "museums text" : "Mientras que visitar las atracciones culturales en Ensenada resultaría ser informativo, asistir a sus eventos artísticos lo dejará entretenido. Desde actuaciones culturales hasta monumentos históricos que le permiten vislumbrar el pasado, eche un vistazo a esta lista para conocer mejor a Ensenada. Inclúyalos en las atracciones de su itinerario en Ensenada y tenga unas excelentes vacaciones.",
                "bars text" : "Hay varios lugares de fiesta toda la noche que se llenan de clientes, especialmente los fines de semana cuando los cruceros se registran en el puerto. Este lugar te da la oportunidad de relajarte de tus preocupaciones diarias.",
                "festivals text" : "En Ensenada encontrarás la Isla Guadalupe, el mejor lugar del mundo para ver tiburones blancos. También tiene un mar en calma, colinas, la gigantesca parte de desierto, parte de valle tropical y sus montañas que enmarcan los parques nacionales; Encontrará pinturas rupestres y un espectacular géiser marino llamado La Bufadora, un profundo cañón submarino que termina en una cueva a riesgo. Este géiser marino se eleva a más de 30 metros sobre el nivel del mar, produciendo un sonido que le dio su nombre.",
                "otherRestaurants" : "Cocina italiana<br>Cocina china<br>Cocina japonesa<br>Cocina mexicana<br>Mariscos<br>",
                "contactus" : "Reservaciones",
                "payments" : "Aceptamos tarjetas de crédito",
                "contact address" : "Avenida Lopez Mateos 1050, Ensenada, BC 22800",
                "connect" : "Conéctate con nosotros",
                "socialmedia" : "Nuestras redes sociales",
                "gallery t":"Galeria de Fotos",
                "footer text": "¿Tienes preguntas sobre nuestros servicios? Contáctanos,  estaremos felices de ayudarte.",
                "amenities title" : "Nuestras instalaciones incluyen:",
                "amenity1" : " Aire acondicionado",
                "amenity2" : " Calefacción",
                "amenity3" : " Television por cable (ESPN, HBO, todos los canales importantes de EE.UU)",
                "amenity4" : " Servicio de habitación",
                "amenity5" : " Sidewalk cafe",
                "amenity6" : " Teléfono en habitación",
                "amenity7" : " Alberca",
                "amenity8" : " Jacuzzi",
                "amenity9" : " Tienda de conveniencia",
                "amenity10" : " Boutiques",
                "amenity11" : " Balcón Panorámico",
                "amenity12" : " Jr. Suites con bañeras grandes",
                "amenity13" : " Estacionamiento Privado",
                "amenity14" : " Seguridad 24 horas",
                "amenity15" : " Linea de Taxi",
                "amenity16" : " Cafetera",
                "amenity17" : " Cerca de las mejores atracciones de Ensenada",
                "amenity18" : " Información Turística",
                "amenity19" : " WIFI Gratis",
                "about" : "Acerca de Ensenada",
                "aboutText" : "Es una ciudad en el estado mexicano de Baja California. Es cabecera del municipio del mismo nombre, y está situada a 31° 52′ de latitud norte y a 116° 37′ de longitud oeste. La ciudad se localiza aproximadamente a 110 km (75 millas) de la frontera México-Estados Unidos, frente al océano Pacífico y fue fundada por Juan Rodríguez Cabrillo. Ensenada fue la capital de Baja California de 1882 a 1915, y la capital se cambió a Mexicali durante la revolución. A la fecha, la ciudad es un centro turístico y un retiro de fin de semana para millones de visitantes al año.<br><br>El municipio de Ensenada cuenta con dos parques nacionales ubicados al sureste del municipio, el Parque nacional Constitución de 1857 (Sierra de Juárez) y San Pedro Mártir, donde se ubica el Observatorio Astronómico Nacional. Ensenada es punta de lanza en investigación científica, tanto básica como aplicada, y ha sido declarada por el ayuntamiento local como Ciudad del Conocimiento. <br><br>En 2018 fue declarada la Zona Metropolitana de Ensenada la cual se comprende por el centro de Ensenada, El Sauzal, ex Ejido Chapultepec, Maneadero, El Zorrillo y Esteban Cantú, Estero de Punta Banda y La Joya. Es conocida como la Capital del Vino Mexicano y Capital del Off Road. Ensenada forma parte de la Red de Ciudades Creativas de UNESCO desde el año 2015. La ciudad es considerada como parte de la organización World Surfing Reserves desde el año 2014.",
                "placestovisit" : "Lugares que visitar",
                "placestovisit_description": "Existe una gran cantidad de lugares a los que puedes visitar mientras disfrutas de tu estancia con nosotros",
                "contactus_description": "Ponte en contacto con nosotros o encuentranos en el mapa",
                "santo_tomas_description": "La vitivinícola más antigua de Ensenada, Baja California con más de 125 años haciendo vino e innovando en aceites de oliva extra virgen. Ubicada a 6 cuadras del hotel",
                "muelle3_description": "Calderos de cerveza – Raciones tradicionales de cantina – Hamburguesas gourmet",
                "guerrerense_description": "Mariscos La Guerrerense se fundó en 1960. De Ensenada para el mundo, sus platillos fusionan innovación, sabor y tradición.<br> Recomendado por Anthony Bourdain",
                "valle_description": "Este Valle esta a unos 30 minutos desde el centro de Ensenada. El área del vino abarca desde la orilla norte de la Ciudad de Ensenada hasta el sur de Tecate, en Baja California.",
                "paipai_description":"Somos una organización que ofrece servicios turísticos eco sustentables, nos enfatizamos en promover el cuidado de los ecosistemas y la conservación de la biodiversidad.",
                "malecon":"El Malécon",
                "malecon_description": "El corto recorrido a pie de menos de un kilómetro por este lugar turístico, ofrece grandes vistas panorámicas del Puerto.<br> Se encuentra a una cuadra de nosotros.",
                "la_bufadora_description": "Es un géiser localizado en la península de Baja California, perteneciente a la ciudad de Ensenada, Baja California, México.",
                "hussongs_description": "Hussong's es el nombre de la cantina más antigua y mejor conocida de Ensenada, Baja California, México. Es también la cantina más vieja de la región de las Californias que comprende a los Estados Unidos y México.",
                "breakfast_info": "Reserva 2 noches y llevate de regalo 1 desayuno para 2 personas.",
                "breakfast_info_disclaimer": "No aplica días festivos ni eventos especiales",
                "open_turism": "Estamos sanitizando constantemente y siguiendo las medidas de seguridad sanitaria recomendadas por las autoridades para proteger a nuestros clientes y empleados",
                "clean_virus_certified": "\"Certificado de limpieza de virus constante\"",
                "pool_open": "Alberca Abierta",
                "new_year_promo_title": "Promoción de invierno $1,200.00 pesos por Noche",
                "new_year_promo_description": "Tarifa para una o dos personas sin impuestos <br> Para estancias del 1ro de enero al 11 de febrero del 2021 previa reservación.",
                "reservation_banner_message": "Quieres reservar con nosotros?",
                "click_here_message": "Da click aquí!"
        }
      }  
    },
    lng: "es",
    fallbackLng: "es",

    interpolation: {
      escapeValue: false
    }
  });
export default i18n;