import React, { Component } from 'react';
import { Trans } from "react-i18next";
import './ContactInfo.css';

class ContactInfo extends Component {
    render() {
        return (
            <section className="rh rh-100 rh-contact">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  alert alert-info">
                           <div className="reservation-banner">
                                <Trans>reservation_banner_message</Trans><br/><br/>
                               <a href="https://reservations.hotelvillafontanainn.com/" className="btn btn-primary"><Trans>click_here_message</Trans></a>
                           </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="reservation-image">
                                {/*<img src="https://res.cloudinary.com/xcytek/image/upload/v1608593889/Hotel%20Villafontana%20Inn/draft_2_n3msc3.png" className="d-block w-100" alt="Third slide"/>*/}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="rh-section-title">
                                <h2><Trans>contactus</Trans></h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-2 col-sm-12">
                        </div>
                        <div className="col-lg-8 col-md-2 col-sm-12">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="rh-contact-add contact-phone">
                                    <div className="icon-box">
                                        <i className="fa fa-phone" aria-hidden="true"></i>
                                    </div>
                                    <p><a href="tel:+52 6461783434">(+52) 646 178 3434</a><br />
                                        <a href="tel:+018000256330">MX: 01 800 025 6330</a>  &nbsp; FREE NATIONAL<br />
                                        <a href="tel:+18004BAJA04">US: 1 800 4BAJA04</a>   &nbsp;&nbsp;&nbsp;&nbsp; FREE US
                            </p>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="rh-contact-add contact-email">
                                    <div className="icon-box">
                                        <i className="fa fa-envelope" aria-hidden="true"></i>
                                    </div>
                                    <p><a href="mailto:reservaciones@hotelvillafontanainn.com">reservaciones@hotelvillafontanainn.com</a><br /><a href="mailto:administracion@hotelvillafontanainn.com">administracion@hotelvillafontanainn.com</a></p>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="rh-contact-add">
                                    <div className="icon-box">
                                        <i className="fa fa-map-marker-alt" aria-hidden="true"></i>
                                    </div>
                                    <p><Trans>contact address</Trans></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-2 col-sm-12">
                        </div>
                    </div>
                    <section className="rh-creditcards">
                        <span><Trans>payments</Trans></span><br /><br />
                        <a href="/#"><img alt="visa" src="https://res.cloudinary.com/xcytek/image/upload/v1608594411/Hotel%20Villafontana%20Inn/visa_logo_6_qdkymv.gif" width="78" height="50" border="0" /></a>
                        <a href="/#"><img alt="Mastercard" src="https://res.cloudinary.com/xcytek/image/upload/v1608594505/Hotel%20Villafontana%20Inn/mastercard_logo_5_z8s47r.gif" width="78" height="50" border="0" /></a>
                        <a href="/#"><img alt="American express" src="https://res.cloudinary.com/xcytek/image/upload/v1608594566/Hotel%20Villafontana%20Inn/American_Express_logo__2018_pu0tfl.svg" width="58" height="50" border="0" /></a>
                        <a href="/#"><img alt="Discover" src="https://res.cloudinary.com/xcytek/image/upload/v1608660278/Hotel%20Villafontana%20Inn/8ee61621-2273-4543-b8ea-f9296f773d4e_fcxjxs.jpg" width="78" height="50" border="0" /></a>
                    </section>
                    <section className="rh rh-map">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <iframe title='Map' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1694.3381432320089!2d-116.6227845!3d31.861023!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80d892657f02c0fb%3A0x8dc858cac4715b82!2sVilla+Fontana+Inn!5e0!3m2!1sen!2smx!4v1553468123800" width="100%" height="500" frameBorder="0" allowFullScreen></iframe>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        );
    }
}
export default ContactInfo;