import React, { Component } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Trans } from "react-i18next";
// import { useTranslation } from 'react-i18next';
import './PlacesNear.css';

// function Restaurants() {
//     return <Trans>restaurants</Trans>
// }
// function Bars() {
//     return <Trans>bars</Trans>
// }
function Museums() {
    return <Trans>museums</Trans>
}
function Festivals() {
    return <Trans>festivals</Trans>
}

class PlacesNear extends Component {
    render() {

        return (

            <section className="rh rh-100 rh-places">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-12 col-md-12">
                            <div className="rh-section-title">
                                <h2><Trans>placestovisit</Trans></h2>
                                <p><Trans>placestovisit_description</Trans></p>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <Tabs defaultActiveKey="Museums" id="tabs-container" className="rh rh-tab-section">
                                {/*<Tab eventKey="Restaurants" title={Restaurants()} className="tab-content">*/}
                                {/*    <div className="tab-content-img">*/}
                                {/*        <img src="https://res.cloudinary.com/xcytek/image/upload/v1608662691/Hotel%20Villafontana%20Inn/restaurant_flf59z.jpg" alt="restaurant-img" />*/}
                                {/*    </div>*/}
                                {/*    <div className="tab-content-detail">*/}
                                {/*        <h2><Trans>restaurants</Trans></h2>*/}
                                {/*        <div className="row">*/}
                                {/*            <div className="col-lg-8 col-md-8">*/}
                                {/*                <p>*/}
                                {/*                    <Trans>*/}
                                {/*                        restaurants text*/}
                                {/*                </Trans>*/}
                                {/*                </p>*/}
                                {/*            </div>*/}
                                {/*            <div className="col-lg-4 col-md-4">*/}
                                {/*                <br />*/}
                                {/*                <Trans>otherRestaurants</Trans>*/}
                                {/*                <a target="_blank"  rel="noopener noreferrer" href="http://laguerrerense.com/">La Guerrerense</a><br/>*/}
                                {/*            </div>*/}

                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</Tab>*/}
                                {/*<Tab eventKey="Bar" title={Bars()} className="tab-content">*/}
                                {/*    <div className="tab-content-img">*/}
                                {/*        <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660301/Hotel%20Villafontana%20Inn/800e31ef-d18b-4484-b147-77cd25c1570c_b1pop6.jpg" alt="our-serivesc-bar" />*/}
                                {/*    </div>*/}
                                {/*    <div className="tab-content-detail">*/}
                                {/*        <h2><Trans>barsnclubs</Trans></h2>*/}
                                {/*        <div className="row">*/}
                                {/*            <div className="col-lg-8 col-md-8">*/}
                                {/*                <p>*/}
                                {/*                    <Trans>*/}
                                {/*                        bars text*/}
                                {/*                </Trans>*/}
                                {/*                </p>*/}
                                {/*            </div>*/}
                                {/*            <div className="col-lg-4 col-md-4">*/}
                                {/*                <br />*/}
                                {/*                <li><a target="_blank"  rel="noopener noreferrer" href="http://www.cantinahussongs.com/home.html">Hussongs Cantina 1888</a></li>*/}
                                {/*            </div>*/}

                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</Tab>*/}
                                <Tab eventKey="Museums" title={Museums()} className="tab-content">
                                    <div className="tab-content-img">
                                        <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660308/Hotel%20Villafontana%20Inn/7536bd20-0c29-4215-b9d5-a1f9f8fb32be_z06ub2.jpg" alt="our-serivesc" />
                                    </div>
                                    <div className="tab-content-detail">
                                        <h2><Trans>museums</Trans></h2>
                                        <div className="row">
                                            <div className="col-lg-8 col-md-8">
                                                <p>
                                                    <Trans>
                                                        museums text
                                                </Trans>
                                                </p>
                                            </div>
                                            <div className="col-lg-4 col-md-4">
                                                <br />
                                                <li><a target="_blank"  rel="noopener noreferrer" href="https://museodelavidyelvinobc.com/">Museo del Vino</a></li>
                                                <li><a target="_blank"  rel="noopener noreferrer" href="https://caracol.org.mx/">Museo Caracol</a></li>
                                            </div>

                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="Festivals" title={Festivals()} className="tab-content">
                                    <div className="tab-content-img">
                                        <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660343/Hotel%20Villafontana%20Inn/bc633b28-269c-40c9-81eb-9baebea40ed4_pscpfj.jpg" alt="our-serivesc-gym" />
                                    </div>
                                    <div className="tab-content-detail">
                                        <h2><Trans>festivals</Trans></h2>
                                        <div className="row">
                                            <div className="col-lg-8 col-md-8">
                                                <p>
                                                    <Trans>
                                                        festivals text
                                                    </Trans>
                                                </p>
                                            </div>
                                            <div className="col-lg-4 col-md-4">
                                                <br />
                                                <li><a target="_blank"  rel="noopener noreferrer" href="http://www.ensenada.cc/la-bufadora.php">La bufadora</a></li>
                                                <li><a target="_blank"  rel="noopener noreferrer" href="https://www.zonaturistica.com/que-hacer-en-el-lugar-turistico/13/el-malecon-ensenada.html">Malec�n</a></li>
                                                <li style={{display: 'none'}}><a target="_blank"  rel="noopener noreferrer" href="https://www.ensenada-baja-vacations.com/carnaval-de-ensenada-baja-california-fechas-y-eventos.html">Carnaval</a></li>
                                                <li><a target="_blank"  rel="noopener noreferrer" href="https://www.ensenada-baja-vacations.com/fiestas-de-la-vendimia-en-ensenada.html">Vendimia</a></li>
                                                <li><a target="_blank"  rel="noopener noreferrer" href="http://score-international.com/baja-1000/">Baja 1000</a></li>
                                                <li><a target="_blank"  rel="noopener noreferrer" href="https://www.paipaiecotourismpark.com/">PaiPai Eco Tourism</a></li>
                                            </div>

                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default PlacesNear;