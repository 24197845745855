import React, { Component } from 'react';

class ContactForm extends Component {
    
    render() {
        
        return (
<section className="rh rh-100 rh-contact-form">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="rh-c-form">
                        <div className="form-area">
                            <form onSubmit={this.submit}>
                                <h3>Contact Form</h3>
                                <div className="row">
                                    <div className="form-group col-lg-12 col-md-12 col-sm-6 col-xs-6 rh-xs-12">
                                        <input type="text" className="form-control" id="name" placeholder="Name" required/>
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-sm-6 col-xs-6 rh-xs-12">
                                        <input type="text" className="form-control" id="formemail" placeholder="Email" required/>
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-sm-6 col-xs-6 rh-xs-12">
                                        <input type="text" className="form-control" id="subject"  placeholder="Subject" required/>
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <textarea className="form-control" id="message" placeholder="Message" maxlength="140" rows="7"></textarea>
                                    </div>
                                    <div className="rh col-xs-12">
                                        <button type="" className="rh-check-btn">Submit Form</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div id="map"></div>
                </div>
            </div>
        </div>
    </section>
        );
}
}
export default ContactForm;