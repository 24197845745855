import React, { Component } from 'react';
// import i18n from "react-i18next";
// import Carousel from 'react-bootstrap/Carousel';
import { Trans } from "react-i18next";
import './RoomsAvailable.css';
import './langs.js'

class RoomsAvailable extends Component {
    render() {
        return (
            <div className="rh">

                <div className="amenities">
                    <div className="amenities-title"><Trans>amenities title</Trans></div>
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-amenities"></div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-amenities">
                            <div className="room-amenities">
                                <p><i className="fas fa-wind"></i><Trans>amenity1</Trans></p>
                                <p><i className="fas fa-thermometer-three-quarters"></i><Trans>amenity2</Trans></p>
                                <p><i className="fas fa-tv"></i><Trans>amenity3</Trans></p>
                                <p><i className="fas fa-concierge-bell"></i><Trans>amenity4</Trans></p>
                                <p><i className="fas fa-mug-hot"></i><Trans>amenity5</Trans></p>
                                <p><i className="fas fa-phone"></i><Trans>amenity6</Trans></p>
                                <p><i className="fas fa-swimmer"></i><Trans>amenity7</Trans></p>
                                <p><i className="fas fa-hot-tub"></i><Trans>amenity8</Trans></p>
                                <p><i className="fas fa-store"></i><Trans>amenity9</Trans></p>
                                <p><i className="fas fa-store-alt"></i><Trans>amenity10</Trans></p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-amenities">
                            <div className="room-amenities">
                                <p><i className="fas fa-binoculars"></i><Trans>amenity11</Trans></p>
                                <p><i className="fas fa-hot-tub"></i><Trans>amenity12</Trans></p>
                                <p><i className="fas fa-parking"></i><Trans>amenity13</Trans></p>
                                <p><i className="fas fa-lock"></i><Trans>amenity14</Trans></p>
                                <p><i className="fas fa-taxi"></i><Trans>amenity15</Trans></p>
                                <p><i className="fas fa-coffee"></i><Trans>amenity16</Trans></p>
                                <p><i className="fas fa-map-marked-alt"></i><Trans>amenity17</Trans></p>
                                <p><i className="fas fa-info"></i><Trans>amenity18</Trans></p>
                                <p><i className="fas fa-wifi"></i><Trans>amenity19</Trans></p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-amenities"></div>
                    </div>
                </div>
                <section className="sort-view-list select-list">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="row">
                                    <div id="sort-by" className="sort-by">
                                        <div id="grid_list" className="sort-by-list">
                                            <div className="rh-flex">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <div className="rh rh-feature-box rh-margin-30">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                                <div className="rh-img">
                                                                    <img src="https://res.cloudinary.com/xcytek/image/upload/v1618201162/Hotel%20Villafontana%20Inn/gallery/cama-queen_fz52fe.jpg" alt="1" />
                                                                    {/*<a></a>*/}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                                <div className="feature-detail">
                                                                    <h4><a href="/#"><Trans>SingleQs title</Trans></a></h4>
                                                                    <p>
                                                                        <Trans>
                                                                            SingleQs text
                                                            </Trans>
                                                                    </p>
                                                                    <li className="book-li">
                                                                        <a className="rh-check-btn" href="https://reservations.hotelvillafontanainn.com/"><Trans>book now</Trans></a>
                                                                    </li>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <div className="rh rh-feature-box rh-margin-30">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                                <div className="rh-img">
                                                                    <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660292/Hotel%20Villafontana%20Inn/84d6f6a2-6270-44d8-a233-ded302323f04_t7l3rq.jpg" alt="1" />
                                                                    {/*<a></a>*/}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                                <div className="feature-detail">
                                                                    <h4><a href="/#"><Trans>SingleKs title</Trans></a></h4>
                                                                    <p>
                                                                        <Trans>
                                                                            SingleKs text
                                                            </Trans>
                                                                    </p>
                                                                    <li className="book-li">
                                                                        <a className="rh-check-btn" href="https://reservations.hotelvillafontanainn.com/"><Trans>book now</Trans></a>
                                                                    </li>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <div className="rh rh-feature-box rh-margin-30">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                                <div className="rh-img">
                                                                    <img src="https://res.cloudinary.com/xcytek/image/upload/v1608588659/Hotel%20Villafontana%20Inn/IMG_0614_dwvlsb.jpg" alt="1" />
                                                                    {/*<a></a>*/}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                                <div className="feature-detail">
                                                                    <h4><a href="/#"><Trans>TwoDs title</Trans></a></h4>
                                                                    <p>
                                                                        <Trans>
                                                                            TwoDs text
                                                            </Trans>
                                                                    </p>
                                                                    <li className="book-li">
                                                                        <a className="rh-check-btn" href="https://reservations.hotelvillafontanainn.com/"><Trans>book now</Trans></a>
                                                                    </li>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <div className="rh rh-feature-box rh-margin-30">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                                <div className="rh-img">
                                                                    <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660608/Hotel%20Villafontana%20Inn/17596581-12fe-406f-93e1-be8b0435a791_vckghu.jpg" alt="1" />
                                                                    {/*<a></a>*/}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                                <div className="feature-detail">
                                                                    <h4><a href="/#"><Trans>DoubleQs title</Trans></a></h4>
                                                                    <p>
                                                                        <Trans>
                                                                            DoubleQs text
                                                            </Trans>
                                                                    </p>
                                                                    <li className="book-li">
                                                                        <a className="rh-check-btn" href="https://reservations.hotelvillafontanainn.com/"><Trans>book now</Trans></a>
                                                                    </li>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <div className="rh rh-feature-box rh-margin-30">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                                <div className="rh-img">
                                                                    <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660351/Hotel%20Villafontana%20Inn/b7470f28-69bb-4af4-b6bf-b0b9250cc3f5_k6vahz.jpg" alt="jr suite" />
                                                                </div>
                                                                {/*<a></a>*/}
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                                <div className="feature-detail">
                                                                    <h4><a href="/#"><Trans>JrSuite title</Trans></a></h4>
                                                                    <p>
                                                                        <Trans>
                                                                            JrSuite text
                                                            </Trans>
                                                                    </p>
                                                                    <li className="book-li">
                                                                        <a className="rh-check-btn" href="https://reservations.hotelvillafontanainn.com/"><Trans>book now</Trans></a>
                                                                    </li>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <div className="rh rh-feature-box rh-margin-30">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                                <div className="rh-img">
                                                                    <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660610/Hotel%20Villafontana%20Inn/14e4170b-c564-4b3a-a904-fb06a299a42a_f5pc5b.jpg" alt="1" />
                                                                    {/*<a></a>*/}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                                <div className="feature-detail">
                                                                    <h4><a href="/#"><Trans>FamSuite title</Trans></a></h4>
                                                                    <p>
                                                                        <Trans>
                                                                            FamSuite text
                                                            </Trans>
                                                                    </p>
                                                                    <li className="book-li">
                                                                        <a className="rh-check-btn" href="https://reservations.hotelvillafontanainn.com/"><Trans>book now</Trans></a>
                                                                    </li>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

            </div>
        );
    }
}
export default RoomsAvailable;