import React, { Component } from 'react';
import { Trans } from "react-i18next";

class AboutService extends Component {
    render() {
        return (
<section className="rh rh-100 rh-about-services">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="rh-section-title">
                        <h2><Trans>placestovisit</Trans></h2>
                        <p><Trans>placestovisit_description</Trans></p>
                    </div>
                </div>
                <div className="row">
                        <div className="col-lg-4 col-md-4">
                            <div className="rh rh-feature-box">
                                <div className="rh-img">
                                    <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660294/Hotel%20Villafontana%20Inn/190bd0aa-2b69-4507-8c15-998963bc703b_bk1yis.jpg" alt="3"/>
                                    {/*<a></a>*/}
                                </div>
                                <div className="feature-detail">
                                    <h4><a href="/#">Bodegas de Santo Tomas</a></h4>
                                    <p><Trans>santo_tomas_description</Trans></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="rh rh-feature-box">
                                <div className="rh-img">
                                    <img src="https://res.cloudinary.com/xcytek/image/upload/v1608662316/Hotel%20Villafontana%20Inn/lges_gtu9jr.jpg" alt="3"/>
                                    {/*<a></a>*/}
                                </div>
                                <div className="feature-detail">
                                    <h4><a href="/#">La Guerrerense</a></h4>
                                    <p><Trans>guerrerense_description</Trans></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="rh rh-feature-box">
                                <div className="rh-img">
                                    <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660286/Hotel%20Villafontana%20Inn/33fa4f36-7917-4d03-83e4-bbe41334b102_lmgctu.jpg" alt="5"/>
                                    {/*<a href="blog-single-page.html"></a>*/}
                                </div>
                                <div className="feature-detail">
                                    <h4><a href="/#">Hussong's Cantina 1888</a></h4>
                                    <p><Trans>hussongs_description</Trans></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="rh rh-feature-box">
                                <div className="rh-img">
                                    <img src="https://res.cloudinary.com/xcytek/image/upload/v1608662381/Hotel%20Villafontana%20Inn/bufadora_hcjhq1.jpg" alt="4"/>
                                    {/*<a></a>*/}
                                </div>
                                <div className="feature-detail">
                                    <h4><a href="/#">La Bufadora</a></h4>
                                    <p><Trans>la_bufadora_description</Trans></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="rh rh-feature-box">
                                <div className="rh-img">
                                    <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660258/Hotel%20Villafontana%20Inn/0f56c9af-bbf8-49a8-a7fd-c98760c52356_a2uemv.jpg" alt="4"/>
                                    {/*<a></a>*/}
                                </div>
                                <div className="feature-detail">
                                    <h4><a href="/#"><Trans>malecon</Trans></a></h4>
                                    <p><Trans>malecon_description</Trans></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="rh rh-feature-box">
                                <div className="rh-img">
                                    <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660274/Hotel%20Villafontana%20Inn/6a80a5f8-ff02-4356-b7e8-dd136c870d68_uroqvj.jpg" alt="5"/>
                                    {/*<a href="blog-single-page.html"></a>*/}
                                </div>
                                <div className="feature-detail">
                                    <h4><a href="/#">Valle de Guadalupe</a></h4>
                                    <p><Trans>valle_description</Trans></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="rh rh-feature-box">
                                <div className="rh-img">
                                    <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660260/Hotel%20Villafontana%20Inn/1de143cb-7e18-433b-aeeb-c4faaacc85a1_dwk7gg.jpg" alt="5"/>
                                    {/*<a href="https://www.paipaiecotourismpark.com/"></a>*/}
                                </div>
                                <div className="feature-detail">
                                    <h4><a href="/#">Pai Pai Eco Tourism</a></h4>
                                    <p><Trans>paipai_description</Trans></p>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </section>
        );
}
}
export default AboutService;