import React, { Component } from 'react';
import Header from '../Components/Header.jsx';
import Gallery from '../Components/Gallery.jsx';
import Newsletter from '../Components/Newsletter.jsx';
// import Footer from '../Components/Footer.jsx';
import { Helmet } from "react-helmet";

class Contact extends Component {
    render() {
        return (
            <div className="gallery-container">
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="keywords" content="Hotel, Ensenada, hotel en ensenada, ensenada hoteles, ensenada hotel, ensenada hoteles, stay in ensenada, gallery, images" />
                    <link rel="canonical" href="http://hotelvillafontanainn.com" />
                    <title>Hotel Villa Fontana Inn</title>
                </Helmet>
                <Header />
                <Gallery />
                <Newsletter />
                {/*<Footer />*/}
            </div>
        );
    }
}
export default Contact;