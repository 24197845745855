import React, { Component } from 'react';
import Header from '../Components/Header.jsx';
import Carousel from '../Components/Carousel.jsx';
import Brochure from '../Components/Brochure.jsx';
import FeaturedRooms from '../Components/FeaturedRooms.jsx';
import PlacesNear from '../Components/PlacesNear.jsx';
import ContactInfo from '../Components/ContactInfo.jsx';
import Newsletter from '../Components/Newsletter.jsx';
import Footer from '../Components/Footer.jsx';
import { Helmet } from "react-helmet";

class Home extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="keywords" content="Hotel, Ensenada, hotel en ensenada, ensenada hoteles, ensenada hotel, ensenada hoteles, stay in ensenada, home" />
                    <link rel="canonical" href="http://hotelvillafontanainn.com" />
                    <title>Hotel Villa Fontana Inn</title>
                </Helmet>
                <Header />
                <Carousel />
                <Brochure />
                <FeaturedRooms />
                <PlacesNear />
                <ContactInfo />
                <Newsletter />
                <Footer />
            </div>
        );
    }
}
export default Home;