import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from "react-i18next";
import './index.css';
import { HashRouter } from "react-router-dom";
import i18n from './Components/langs.js'
// import './Components/langs.js'
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <HashRouter>
        <App />
    </HashRouter>
  </I18nextProvider>,
  document.getElementById('root'),
);

// React 18
// const container = document.getElementById('root');
// const root = createRoot(container);
// root.render(
//     <I18nextProvider i18n={i18n}>
//         <HashRouter>
//             <App />
//         </HashRouter>
//     </I18nextProvider>
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
