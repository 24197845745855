import React, { Component } from 'react';
import Header from '../Components/Header.jsx';
import AboutPage from '../Components/AboutPage.jsx';
import AboutService from '../Components/AboutService.jsx';
import Newsletter from '../Components/Newsletter.jsx';
// import Footer from '../Components/Footer.jsx';
import { Helmet } from "react-helmet";

class About extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="keywords" content="Hotel, Ensenada, hotel en ensenada, ensenada hoteles, ensenada hotel, ensenada hoteles, stay in ensenada, about, information" />
                    <link rel="canonical" href="http://hotelvillafontanainn.com" />
                    <title>Hotel Villa Fontana Inn</title>
                </Helmet>
                <Header />
                <AboutPage />
                <AboutService />
                <Newsletter />
                {/*<Footer />*/}
            </div>
        );
    }
}
export default About;