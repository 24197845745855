import React, { Component } from 'react';
import { Trans } from "react-i18next";
import './Footer.css';
import logo from '../images/logo.png';
class Footer extends Component {
    render() {
        const getYear = new Date().getFullYear()
        return (
            <footer className="rh">
    <div className="rh-100 rh-footer">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-md-4 col-sm-4 col-xs-6 rh-f-30 rh-f-100">
                    <div className="rh-footer-box">
                        <img className="footer-logo" src={logo} alt="footer-logo" />
                        <p><Trans>footer text</Trans></p>
                        <ul className="footer-about">
                            <li><a href="/#"><Trans>contact address</Trans></a></li>
                            <li><a href="mailto:reservaciones@hotelvillafontanainn.com">reservaciones@hotelvillafontanainn.com</a></li>
                            <li><a href="mailto:administracion@hotelvillafontanainn.com">administracion@hotelvillafontanainn.com</a></li>
                            <li><a href="tel:+52-6461783434">(+52) 646 178 3434</a></li>
                            <li><a href="tel:+18004BAJA04">US: 1 800 4BAJA04</a></li>
                            <li><a href="tel:+018000256330">MX: 01 800 025 6330</a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-6 col-md-4 col-sm-4 col-xs-6 rh-f-100">
                    <div className="rh-footer-box gallery-box">
                        <a href="/#/gallery"><h5><Trans>gallery t</Trans></h5></a>
                        <ul className="footer-menu gallery">
                            <li>
                                <a href="/#/gallery"><img src="https://res.cloudinary.com/xcytek/image/upload/v1608660288/Hotel%20Villafontana%20Inn/40e3342e-e217-4083-b00d-b5fa084eb8c2_rdidud.jpg" alt="footer-gallery-1" /></a>
                            </li>
                            <li>
                                <a href="/#/gallery"><img src="https://res.cloudinary.com/xcytek/image/upload/v1608576540/Hotel%20Villafontana%20Inn/footer-gallery-5_itdlfr.jpg" alt="footer-gallery-2" /></a>
                            </li>
                            <li>
                                <a href="/#/gallery"><img src="https://res.cloudinary.com/xcytek/image/upload/v1608660265/Hotel%20Villafontana%20Inn/3cd02022-c17e-4c90-a477-3a0f3f0d2b9d_d6tke1.jpg" alt="footer-gallery-3" /></a>
                            </li>
                            <li>
                                <a href="/#/gallery"><img src="https://res.cloudinary.com/xcytek/image/upload/v1608660299/Hotel%20Villafontana%20Inn/693dd1ba-ce3a-49da-9de9-40c647d30d9a_zkmlff.jpg" alt="footer-gallery-4" /></a>
                            </li>
                            <li>
                                <a href="/#/gallery"><img src="https://res.cloudinary.com/xcytek/image/upload/v1608660290/Hotel%20Villafontana%20Inn/68a7c002-b4f5-4548-a21d-7e2306da7143_exs3jo.jpg" alt="footer-gallery-5" /></a>
                            </li>
                            <li>
                                <a href="/#/gallery"><img src="https://res.cloudinary.com/xcytek/image/upload/v1608576540/Hotel%20Villafontana%20Inn/footer-gallery-4_izz0dp.jpg" alt="footer-gallery-6" /></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="rh footer-btm">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h6 className="pull-left">Hotel Villa Fontana Inn {getYear}</h6>
                </div>
            </div>
        </div>
    </div>
</footer>
        );
}
}
export default Footer;