import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Trans } from "react-i18next";
import './Carousel.css';

class CarouselSlide extends Component {
    render() {
        const carusel1 = 'https://res.cloudinary.com/xcytek/image/upload/v1608589679/Hotel%20Villafontana%20Inn/IMG_1591_t68jpf.jpg'
        const carusel2 = 'https://res.cloudinary.com/xcytek/image/upload/v1608660273/Hotel%20Villafontana%20Inn/5e0d8e21-9040-49ad-bd62-b97cc5643ed1_q0dqfp.jpg'
        const carusel3 = 'https://res.cloudinary.com/xcytek/image/upload/v1608660344/Hotel%20Villafontana%20Inn/ba49b1ab-05c3-4b77-973e-4a30452beca7_pyyzlq.jpg'
        return (
    <Carousel id="myCarousel" className="carousel slide" data-ride="carousel">
            <Carousel.Item>
                <img src={carusel1} className="d-block w-100" alt="first slide"/>
                <Carousel.Caption>
                <div className="rh rh-banner-widgets">
                    <h1><Trans>caption1</Trans></h1>
                </div>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img src={carusel2} className="d-block w-100" alt="Third slide"/>
                <Carousel.Caption>
                <div className="rh rh-banner-widgets">
                    <h1><Trans>caption2</Trans></h1>
                </div>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img src={carusel3} className="d-block w-100" alt="Third slide"/>
                <Carousel.Caption>
                <div className="rh rh-banner-widgets">
                        <h1><Trans>caption3</Trans></h1>
                </div>
                </Carousel.Caption>
            </Carousel.Item>
    </Carousel>
);
}
}
export default CarouselSlide;