import React, { Component } from 'react';
import { Trans } from "react-i18next";
import './RoomsAvailable.css';
import './FeaturedRooms.css';

class FeaturedRooms extends Component {
    render() {
        return (
    <section className="rh rh-100 rh-featured-rooms">
        <div className="container">
            <div className="row">

                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="rh-section-title">
                        <h2><Trans>featured</Trans></h2>
                        <p><Trans>ft room text</Trans></p>
                    </div>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 rh-mf-30">
                    <div className="rh rh-feature-box">
                        <div className="rh-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660292/Hotel%20Villafontana%20Inn/84d6f6a2-6270-44d8-a233-ded302323f04_t7l3rq.jpg" alt="Habitacion" />
                            {/*<a href="/#/rooms/"></a>*/}
                        </div>
                        <div className="feature-detail">
                            <h4><a href="/#/rooms/"><Trans>SingleKs title</Trans></a></h4>
                            <div className="rh">
                                <p><Trans>SingleKs text</Trans></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 rh-mf-30">
                    <div className="rh rh-feature-box">
                        <div className="rh-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660608/Hotel%20Villafontana%20Inn/17596581-12fe-406f-93e1-be8b0435a791_vckghu.jpg" alt="Habitacion" />
                            {/*<a href="/#/rooms/"></a>*/}
                        </div>
                        <div className="feature-detail">
                            <h4><a href="/#/rooms/"><Trans>DoubleQs title</Trans></a></h4>
                            <div className="rh">
                                <p><Trans>DoubleQs text</Trans></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660610/Hotel%20Villafontana%20Inn/14e4170b-c564-4b3a-a904-fb06a299a42a_f5pc5b.jpg" alt="Habitacion" />
                            {/*<a href="/#/rooms/"></a>*/}
                        </div>
                        <div className="feature-detail">
                            <h4><a href="/#/rooms/"><Trans>FamSuite title</Trans></a></h4>
                            <div className="rh">
                                <p><Trans>FamSuite text</Trans></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        );
}
}
export default FeaturedRooms;